// import { toRaw } from 'vue'
import CONSTANTS from '../data/constants';

export default {
  getLocalISOString: (date) => {
    const offset = date.getTimezoneOffset();
    const offsetAbs = Math.abs(offset);
    const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString();
    return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(Math.floor(offsetAbs / 60))
            .padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`;
  },

  getToday: () => {
    // const nzTimezoneOffset = 12 * 60 // New Zealand is UTC+12
    const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Pacific/Auckland' }));
    const nzTime = new Date(now.getTime());
    const nzYear = nzTime.getFullYear();
    const nzMonth = nzTime.getMonth() + 1; // Months are zero-based, so add 1
    const nzDay = nzTime.getDate();
    return `${nzYear}-${nzMonth.toString().padStart(2, '0')}-${nzDay.toString().padStart(2, '0')}`;
  },

  getNextMonthDate: function () {
    const today = new Date(this.getToday());
    const currentDay = today.getDate();
    const nextMonth = new Date(new Date(today.getFullYear(), today.getMonth() + 1, currentDay)
      .toLocaleString('en-US', { timeZone: 'Pacific/Auckland' }));
    if (nextMonth.getMonth() !== (today.getMonth() + 1) % 12) {
      nextMonth.setMonth(nextMonth.getMonth(), 0);
    }
    return this.getLocalISOString(nextMonth).split('T')[0];
  },

  getFirstPaymentDate: function (selectedFreq) {
    if (selectedFreq.value === 'M') {
      return this.getNextMonthDate();
    } else if (selectedFreq.value === 'B') {
      return new Date(new Date().setDate(new Date().getDate() + 14))
        .toISOString()
        .slice(0, 10);
    }
    return new Date(new Date().setDate(new Date().getDate() + 7))
      .toISOString()
      .slice(0, 10);
  },

  runOnPageLoad: function () {
    const favicon = document.getElementById('favicon');
    const pageTitle = document.getElementById('page_title');
    favicon.href = process.env.BASE_URL + 'favicon.ico';
    pageTitle.text = 'Land Rover Finance Calculator';
    document.documentElement.style.setProperty('--button-accent', '#0C121C');
    document.documentElement.style.setProperty('--button-color', '#ffffff');
    document.documentElement.style.setProperty('--header-line-bg', '#e6f6fc');
    document.documentElement.style.setProperty('--tooltip-accent', '#000000');
    document.documentElement.style.setProperty('--font', '\'Avenir\', sans-serif');
  }
};

const termOptions = () => CONSTANTS.TERM_OPTIONS;
const mileageOptions = () => CONSTANTS.MILEAGE_OPTIONS;
const frequencyOptions = () => CONSTANTS.FREQUENCY_OPTIONS;
const financeProducts = () => CONSTANTS.FINANCE_PRODUCTS;
const interestRate = () => CONSTANTS.INITIAL_INTEREST_RATE;
const establishmentFees = () => CONSTANTS.ESTABLISHMENT_FEES;
const getRadioLabelText = (str) => `<span class="radio_label_text">${str}</span>`;

export {
  termOptions, mileageOptions, frequencyOptions, financeProducts, getRadioLabelText,
  interestRate, establishmentFees
};
