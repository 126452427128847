/* eslint-disable */
const modelImages = [
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '90 D250 S', image: 'lr-defender-90.jpg', image2x: 'lr-defender-90-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '90 V8', image: 'lr-defender-90.jpg', image2x: 'lr-defender-90-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 D250 S', image: 'lr-defender-110.jpg', image2x: 'lr-defender-110-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 D300 SE', image: 'lr-defender-110.jpg', image2x: 'lr-defender-110-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 D300 S', image: 'lr-defender-110.jpg', image2x: 'lr-defender-110-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 P400 SE', image: 'lr-defender-110.jpg', image2x: 'lr-defender-110-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 D300 HSE', image: 'lr-defender-110.jpg', image2x: 'lr-defender-110-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 P400E HSE', image: 'lr-defender-110.jpg', image2x: 'lr-defender-110-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 V8', image: 'lr-defender-110.jpg', image2x: 'lr-defender-110-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '130 D300 SE', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '130 D300 HSE', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '90 425 EDITION', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 D300 X-DYNAMIC SE', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 D300 X-DYNAMIC HSE', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 425 EDITION', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '130 D300 X-DYNAMIC HSE', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '130 425 EDITION', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 OCTA', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },
  { brand: 'Land Rover', model: 'DEFENDER', submodel: '110 OCTA EDITION ONE', image: 'lr-defender-130.jpg', image2x: 'lr-defender-130-2x.jpg' },

  { brand: 'Land Rover', model: 'DISCOVERY SPORT', submodel: 'P250 DYNAMIC SE', image: 'lr-discovery-sport.jpg', image2x: 'lr-discovery-sport-2x.jpg' },

  { brand: 'Land Rover', model: 'DISCOVERY', submodel: 'D250 DYNAMIC SE', image: 'lr-discovery.jpg', image2x: 'lr-discovery-2x.jpg' },
  { brand: 'Land Rover', model: 'DISCOVERY', submodel: 'D300 DYNAMIC HSE', image: 'lr-discovery.jpg', image2x: 'lr-discovery-2x.jpg' },
  { brand: 'Land Rover', model: 'DISCOVERY', submodel: 'D300 METROPOLITAN EDITION', image: 'lr-discovery.jpg', image2x: 'lr-discovery-2x.jpg' },
  { brand: 'Land Rover', model: 'DISCOVERY', submodel: 'D250 R-DYNAMIC SE', image: 'lr-discovery.jpg', image2x: 'lr-discovery-2x.jpg' },
  { brand: 'Land Rover', model: 'DISCOVERY', submodel: 'D350 DYNAMIC HSE', image: 'lr-discovery.jpg', image2x: 'lr-discovery-2x.jpg' },
  { brand: 'Land Rover', model: 'DISCOVERY', submodel: 'D350 METROPOLITAN EDITION', image: 'lr-discovery.jpg', image2x: 'lr-discovery-2x.jpg' },


  { brand: 'Land Rover', model: 'RANGE ROVER EVOQUE', submodel: 'P250 DYNAMIC SE', image: 'RR-Evoque.jpg', image2x: 'RR-Evoque-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER EVOQUE', submodel: 'P300E DYNAMIC SE', image: 'RR-Evoque.jpg', image2x: 'RR-Evoque-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER EVOQUE', submodel: 'P250 DYNAMIC HSE', image: 'RR-Evoque.jpg', image2x: 'RR-Evoque-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER EVOQUE', submodel: 'P270E DYNAMIC SE', image: 'RR-Evoque.jpg', image2x: 'RR-Evoque-2x.jpg' },

  { brand: 'Land Rover', model: 'RANGE ROVER VELAR', submodel: 'P250 R-DYNAMIC SE', image: 'lr-velar.jpg', image2x: 'lr-velar-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER VELAR', submodel: 'P400E R-DYNAMIC SE', image: 'lr-velar.jpg', image2x: 'lr-velar-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER VELAR', submodel: 'P400 R-DYNAMIC HSE', image: 'lr-velar.jpg', image2x: 'lr-velar-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER VELAR', submodel: 'P400E R-DYNAMIC HSE', image: 'lr-velar.jpg', image2x: 'lr-velar-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER VELAR', submodel: 'P250 DYNAMIC SE', image: 'lr-velar.jpg', image2x: 'lr-velar-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER VELAR', submodel: 'P400E DYNAMIC SE', image: 'lr-velar.jpg', image2x: 'lr-velar-2x.jpg' },  
  { brand: 'Land Rover', model: 'RANGE ROVER VELAR', submodel: 'P400 DYNAMIC HSE', image: 'lr-velar.jpg', image2x: 'lr-velar-2x.jpg' },  
  { brand: 'Land Rover', model: 'RANGE ROVER VELAR', submodel: 'P400E DYNAMIC HSE', image: 'lr-velar.jpg', image2x: 'lr-velar-2x.jpg' },  

  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'D300 DYNAMIC SE', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'D350 DYNAMIC HSE', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'P400 DYNAMIC HSE', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'P460E DYNAMIC SE', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'P460E DYNAMIC HSE', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'D250 SE', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'P550E DYNAMIC SE', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'P530 AUTOBIOGRAPHY', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER SPORT', submodel: 'SV EDITION TWO', image: 'RR-Sport.jpg', image2x: 'RR-Sport-2x.jpg' },

  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'D350 HSE SWB', image: 'RR.jpg', image2x: 'RR-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'P530 HSE SWB', image: 'RR.jpg', image2x: 'RR-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'P550E HSE SWB', image: 'RR.jpg', image2x: 'RR-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'P615 SV SWB', image: 'RR.jpg', image2x: 'RR-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'P550E SV SWB', image: 'RR.jpg', image2x: 'RR-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'D350 HSE LWB (7)', image: 'RR.jpg', image2x: 'RR-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'P550E AUTOBIOGRAPHY SWB', image: 'RR.jpg', image2x: 'RR-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'P530 AUTOBIOGRAPHY SWB', image: 'RR.jpg', image2x: 'RR-2x.jpg' },
  { brand: 'Land Rover', model: 'RANGE ROVER', submodel: 'P530 AUTOBIOGRAPHY LWB (7)', image: 'RR.jpg', image2x: 'RR-2x.jpg' }
]

const imagesData = {
  getImage1x: function (brand, model, submodel) {
    const submodelString = (typeof submodel === 'string') ? submodel : submodel.SubModel

    const result = modelImages.find(el => {
      return el.brand === brand && el.model.toLowerCase() === model.toLowerCase()
    })
    if (!result) return null
    return '/img/' + (result.image ? result.image : null)
  },
  getImage2x: function (brand, model, submodel) {
    const submodelString = (typeof submodel === 'string') ? submodel : submodel.SubModel

    const result = modelImages.find(el => {
      return el.brand === brand && el.model.toLowerCase() === model.toLowerCase()
    })
    if (!result) return null
    return '/img/' + (result.image2x ? result.image2x : result.image ? result.image : null)
  }
}

export default imagesData
