
<template>
    <header>
      <div class="container">
        <img src='../../src/assets/landrover-logo.svg' alt="Land Rover logo" class="header_logo" />
      </div>
    </header>
</template>

<script>

export default {
  name: 'HeaderComponent'
};
</script>
