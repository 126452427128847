import { createRouter, createWebHistory } from 'vue-router';

import LandroverContactUs from '../components/LandroverContactUs.vue';
import LandroverFinanceCalculator from '../components/LandroverFinanceCalculator.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: LandroverFinanceCalculator
  },
  {
    path: '/landrover',
    name: 'landrover-finance-calculator',
    component: LandroverFinanceCalculator
  },
  {
    path: '/landrover/calculator',
    component: LandroverFinanceCalculator
  },
  {
    path: '/landrover/contact-us',
    name: 'landrover-contact-us',
    component: LandroverContactUs,
    props: true
  },
  {
    path: '/*',
    name: 'error',
    component: LandroverFinanceCalculator
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export { routes };

export default router;
