<template>
    <span class="loading">
        <div class="stage">
            <div class="dot-collision"></div>
        </div>
    </span>
</template>

<script>
export default {
  name: 'LoaderComponent'
};
</script>

<style lang="scss">
$dark: #3c3c3b;
.loading {
    .stage {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 0;
        margin: 0 -5%;
        overflow: hidden;
        width: 8rem;
    }

    .dot-collision {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $dark;
        color: $dark;

        &::before,
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
        }

        &::before {
            left: -10px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: $dark;
            color: $dark;
            animation: dotCollisionBefore 2s infinite ease-in;
        }

        &::after {
            left: 10px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: $dark;
            color: $dark;
            animation: dotCollisionAfter 2s infinite ease-in;
            animation-delay: 1s;
        }
    }

}

@keyframes dotCollisionBefore {

    0%,
    50%,
    75%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-15px) scale(0.8);
    }
}

@keyframes dotCollisionAfter {

    0%,
    50%,
    75%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(15px) scale(0.8);
    }
}
</style>
