const dealersData = {
  dealers: [
    { label: 'Archibald and Shorter Greenlane', code: 'LRASG' },
    { label: 'Archibald and Shorter Northshore', code: 'LRASN' },
    { label: 'Archibalds Motors Christchurch', code: 'LSAMC' },
    { label: 'Armstrong Prestige Dunedin', code: 'LRAPD' },
    { label: 'Armstrong Prestige Wellington', code: 'LRAPW' },
    { label: 'Bayswater European Hawkes Bay', code: 'LRBHB' },
    { label: 'Duncan & Ebbett Hamilton', code: 'LRDEH' },
    { label: 'Ebbett Prestige Tauranga', code: 'LREPT' }
  ]
};

export default dealersData;
