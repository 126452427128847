<template>
    <div>
        <HeaderComponent />
        <main>
            <div class="top_line" v-if="referrer">
                <div class="container">
                    <a :href="referrer" class="back_link">Back</a>
                </div>
            </div>
            <div class="container" :data-models="modelsDataString" data-test-id="models_data_container">
                <h1 data-test-id="header-Landrover">Finance calculator</h1>
                <div class="calculator_wrap">
                    <button v-if="isMobile" class="section_toggle"
                        :class="{ active: activeSection === 'calculator' }"
                        v-on:click="setActiveSection('calculator')">
                        <span class="toggle_number">01</span>
                        <span class="toggle_text">Calculate</span>
                    </button>

                    <section class="calculator_section section_calc" v-if="showCalculatorSection">
                        <div class="form_part">
                            <div class="input_wrap">
                                <span class="label_text">Model:</span>
                                <Multiselect
                                    id="model"
                                    :taggable="false"
                                    :clearable="false"
                                    :min-chars="1" :searchable="true"
                                    autocomplete="off"
                                    v-model="selectedModel"
                                    label="label"
                                    :options="modelsArray"
                                    data-test-id="select-model"
                                    data-cy='selectedModel'
                                    placeholder="Please select"
                                >
                                </Multiselect>
                            </div>
                        </div>

                        <div class="form_part" v-if="hasSubmodel">
                            <div class="input_wrap">
                                <span class="label_text">Sub-model:</span>
                                <Multiselect id="submodel" :taggable="false" :clearable="false"
                                    :min-chars="1" :searchable="true"
                                    v-model="selectedSubmodel"
                                    :options="cleanSubmodelsArray"
                                    autocomplete="off"
                                    data-test-id="select-submodel"
                                    v-on:change="(e) => submodelChange(e)"
                                    data-cy='selectedSubmodel'>
                                    <template v-slot:singlelabel="{ value }">
                                        <div class="multiselect-single-label">
                                            <span class="multiselect-single-label-text">
                                                {{ formatCarSubmodel(value).label }} -
                                                <b>{{ getCurrency(formatCarSubmodel(value).rrp, true) }}</b>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <span class="multiselect-single-label-text">
                                            {{ formatCarSubmodel(option).label }} -
                                            <b>{{ getCurrency(formatCarSubmodel(option).rrp, true) }}</b>
                                        </span>
                                    </template>
                                </Multiselect>
                            </div>
                        </div>

                        <div class="form_part">
                            <div class="input_wrap radio_selection prod_selection">
                                <span class="label_text">Finance product:</span>
                                <label v-for="(item, idx) in financeProducts()" :key="idx"
                                    class="styled_radio">
                                    <input type="radio" v-model="selectedProduct" :value="item.id"
                                        :data-cy="idx === 0 ? 'firstproduct' : ''"
                                        :data-firstproduct="item.id"
                                        name="selectedProduct" />
                                    <span class="radio_text"
                                        v-html="getRadioLabelText(item.title)"
                                        data-test-id="select-product"></span>
                                </label>
                            </div>

                            <a :href="whatIsIownLink" class="help_link" target="_blanc">
                                What is Land Rover Freedom?</a>
                        </div>

                        <div class="form_part">
                            <div class="input_wrap">
                                <span class="label_text">Deposit:</span>
                                <cleave v-model.lazy="deposit" :options="currencyOptions"
                                    class="styled_input" name="deposit"
                                    data-test-id="input-deposit" data-cy="deposit">
                                </cleave>

                                <span class="input_error" v-if="deposit > depositLimit">
                                    Deposit can not be higher than ${{ Math.floor(depositLimit) }}
                                </span>

                                <span v-if="showDepositTip && deposit > 0" class="input_error">
                                    Deposit can not be higher
                                    than {{ getCurrency(depositLimit, true) }}
                                </span>
                            </div>
                        </div>

                        <div class="form_part">
                            <div class="input_wrap">
                                <span class="label_text">Loan term:</span>
                                <Multiselect id="selectedTerm" :taggable="false" :clearable="false"
                                    :searchable="false" data-cy="selectedTerm"
                                    v-model="selectedTerm"
                                    :options="termOptions()"
                                    data-test-id="select-term">
                                </Multiselect>
                            </div>
                        </div>

                        <div class="form_part">
                            <div class="input_wrap radio_selection freq_selection">
                                <span class="label_text">Repayment frequency:</span>
                                <label v-for="(item, idx) in frequencyOptions()"
                                    :key="idx" class="styled_radio ">
                                    <input type="radio" v-model="selectedFreq"
                                        :value="item.id" name="selectedFreq" />
                                    <span class="radio_text" data-test-id="select-freq-weekly"
                                        v-html="getRadioLabelText(item.title)">
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="form_part" v-if="selectedProduct === 'gfv'">
                            <div class="input_wrap">
                                <span class="label_text">Expected annual KM:</span>
                                <Multiselect id="selectedMileage" :taggable="false" :clearable="false"
                                    :searchable="false" data-cy="selectedMileage"
                                    v-model="selectedMileage"
                                    :options="mileageOptions()"
                                    data-test-id="select-mileage">
                                </Multiselect>
                            </div>
                        </div>

                        <div class="form_part">
                            <div class="input_wrap">
                                <span class="label_text lowercase_desktop">
                                    Estimated interest rate:
                                    {{ interestRate().toFixed(2) }}% p.a.</span>
                                <p class="content" v-html="ratesText"></p>
                            </div>
                        </div>
                    </section>

                    <button v-if="isMobile" class="section_toggle"
                        :class="{ active: activeSection === 'quote' }"
                        v-on:click="setActiveSection('quote')" data-test-id="open-quote-mobile-button">
                        <span class="toggle_number">02</span>
                        <span class="toggle_text">Your quote</span>
                    </button>

                    <section class="calculator_section section_quote"
                        v-if="activeSection === 'quote' | !isMobile">
                        <span class="quote_model">Land Rover {{ selectedModel }}</span>
                        <span class="quote_submodel">
                            {{ selectedSubmodel && selectedSubmodel.SubModel ?
                                selectedSubmodel.SubModel : selectedSubmodel }}
                        </span>
                        <img :src="selectedModelImage"
                            :srcset="selectedModelImageSrcset +' 2x,' + selectedModelImage + ' 1x'"
                            :alt="selectedModel"
                            class="model_image" />

                        <div class="repayments">
                            <span class="repayments_numbers">
                                <span class="repayments_value">
                                    <LoaderComponent v-if="calculatorRequestProcessing" />
                                    <span>{{ getCurrency(repayments, false) }} </span>
                                </span>
                                <small> / {{ frequencyWord }}</small>
                            </span>

                            <span class="estimated_repayments_header">Estimated repayment</span>
                        </div>

                        <div class="quote_breakdown" v-if="hasSubmodel">
                            <div class="quote_line">
                                <span class="ql_title">Vehicle price</span>
                                <span class="ql_value">
                                    {{ getCurrency(selectedSubmodelObject.RRP, false) }}
                                </span>
                            </div>
                            <div class="quote_line" v-if="selectedProduct === 'gfv'">
                                <span class="ql_title">Land Rover Freedom</span>
                                <span class="ql_value">
                                    <LoaderComponent v-if="calculatorRequestProcessing" />
                                    <span v-if="calculatorRequestProcessing">...calculating</span>
                                    <span v-else>{{ getCurrency(total.gfv, false) }}</span>
                                </span>
                            </div>
                            <div class="quote_line">
                                <span class="ql_title">Total interest</span>
                                <span class="ql_value">
                                    <LoaderComponent v-if="calculatorRequestProcessing" />
                                    <span v-if="calculatorRequestProcessing">...calculating</span>
                                    <span v-else>
                                        <span v-if="total.interest">
                                            {{ getCurrency(total.interest, false) }}
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div class="quote_line">
                                <span class="ql_title">Establishment fees</span>
                                <span class="ql_value">
                                    <LoaderComponent v-if="calculatorRequestProcessing" />
                                    <span v-if="calculatorRequestProcessing">...calculating</span>
                                    <span v-else>{{ getCurrency(establishmentFees(), false) }}</span>
                                </span>
                            </div>
                            <div class="quote_line">
                                <span class="ql_title">Total cost</span>
                                <span class="ql_value">
                                    <LoaderComponent v-if="calculatorRequestProcessing" />
                                    <span v-if="calculatorRequestProcessing">...calculating</span>
                                    <span v-else>
                                        <span v-if="total.loan">{{ getCurrency(total.loan, false) }}</span>
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="quote_bottom">
                            <p class="content">
                                Note: the picture may not depict the sub-model you've chosen
                            </p>

                            <div class="controls right_aligned">
                                <router-link ref="enquireButton"
                                    v-if="calculationParams"
                                    :to="{ name: 'landrover-contact-us', query: calculationParams }"
                                    v-slot="{ navigate, route }">
                                    <a :href="route.fullPath" class="enquire_button" @click="navigate"
                                        data-test-id="contact-page-link">Enquire</a>
                                </router-link>
                            </div>
                        </div>

                    </section>

                    <div class="disclaimer" v-if="showQuoteSection">
                        <h6>Disclaimer:</h6>
                        <p>
                            Your repayments have been calculated at the estimated interest
                            rate of {{ iirForDisclaimer }}% p.a.
                            However, if you apply for a loan and your application is successful,
                            your interest rate and repayment amount may be different
                            to those shown above.
                            An establishment fee of ${{ estFeeForDisclaimer }} and
                            ${{ ppsrFeeForDisclaimer }} PPSR fee applies to each loan,
                            the repayment amount shown above includes repayment of that.
                        </p>
                        <p>
                            The repayments have been calculated on the base standard retail purchase
                            price of the {{ brandWord }} model selected and excludes on road cost.
                            We endeavour to ensure that all information on this website
                            is accurate at the time of upload to the website.
                            However product features, models, illustrations, representations,
                            specifications and prices are subject to change without notice.
                            For more information please contact your local {{ brandWord }} dealer.
                        </p>
                    </div>
                </div>
            </div>
        </main>
        <FooterComponent />
    </div>
</template>

<script>
import { defineComponent, ref, reactive, watch, onMounted, computed, toRaw, nextTick } from 'vue';
import axios from 'axios';
import debounce from 'lodash.debounce';
import HeaderComponent from './HeaderComponent.vue';
import FooterComponent from './FooterComponent.vue';
import LoaderComponent from './LoaderComponent.vue';
import Multiselect from '@vueform/multiselect';
import Cleave from 'vue-cleave-component';
import commonMixin,
{
  getRadioLabelText,
  frequencyOptions,
  termOptions,
  mileageOptions,
  financeProducts,
  interestRate,
  establishmentFees
} from '../mixins/commonMixin.js';
import CONSTANTS from '../data/constants';
import imagesData from '@/data/images_data';
import loadedModelsData from '../data/models_data_landrover.json';

export default defineComponent({
  name: 'LandroverFinanceCalculator',
  components: {
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    Multiselect,
    Cleave
  },
  setup() {
    const brand = CONSTANTS.BRANDS.LANDROVER;
    const currencyOptions = CONSTANTS.CURRENCY_FIELD_OPTIONS;
    const whatIsIownLink = CONSTANTS.WHAT_IS_IOWN_LINK.LANDROVER;
    const iirForDisclaimer = CONSTANTS.INITIAL_INTEREST_RATE.toFixed(2);
    const estFeeForDisclaimer = CONSTANTS.EST_FEE;
    const ppsrFeeForDisclaimer = CONSTANTS.PPSR_FEE;
    const ratesText = CONSTANTS.RATES_TEXT;
    const brandWord = 'Land Rover';

    const modelsData = reactive(loadedModelsData);
    const models = reactive([]);
    const modelsDataString = ref('');
    const isMobile = ref(false);
    const selectedModel = ref('');
    const selectedModelSubmodels = ref([]);
    const selectedSubmodel = ref('');
    const selectedSubmodelObject = ref({});
    const selectedProduct = ref({});
    const selectedFreq = ref('');
    const selectedTerm = ref(0);
    const selectedMileage = ref(0);
    const depositLimit = ref(0);
    const showDepositTip = ref(false);
    const repayments = ref(0);
    const calculatorRequestProcessing = ref(false);
    const initialLoad = ref(true);
    const deposit = ref(null);
    const cancelGFV = ref(null);
    const calcDebounce = ref(null);
    const activeSection = ref('calculator');
    const total = ref({});
    const cancelInterest = ref(null);
    const referrer = ref('');

    const selectSubmodels = (attr1) => {
      if (!attr1 || models.value.indexOf(attr1) < 0) {
        return [];
      }
      return toRaw(modelsData).filter((el) => el.Model === attr1);
    };

    const modelsArray = computed(() => {
      return [...new Set(toRaw(modelsData).map(car => car.Model))];
    });

    const submodelsArray = computed(() => {
      return [...new Set(toRaw(selectedModelSubmodels.value).map(
        (car) => {
          return {
            SubModel: car.SubModel,
            RRP: car.RRP
          };
        }
      ))];
    });

    const frequencyWord = computed(() => {
      switch (selectedFreq.value) {
        case 'W':
          return CONSTANTS.FREQUENCY_OPTIONS[2].title;
        case 'B':
          return CONSTANTS.FREQUENCY_OPTIONS[1].title;
        default:
          return CONSTANTS.FREQUENCY_OPTIONS[0].title;
      }
    });

    const hasSubmodel = computed(() => {
      return !!selectedSubmodelObject.value;
    });

    const getWbsSeqNo = computed(() => {
      return selectedTerm.value.months
        ? selectedTerm.value.months / 12 * 52
        : selectedTerm.value / 12 * 52;
    });
    const cleanSubmodelsArray = computed(() => {
            const cleanSubmodels = selectedModelSubmodels.value.map(function (el) {
                return el.SubModel;
            });
            return cleanSubmodels;
    });

    const selectedModelImage = computed(() => {
      let imageData = null;
      try {
        imageData = imagesData.getImage1x(
          brand,
          selectedModel.value,
          selectedSubmodel.value
        );
      } catch {
        imageData = null;
      }
      return imageData;
    });

    const selectedModelImageSrcset = computed(() => {
      let imageData = null;
      try {
        imageData = imagesData.getImage2x(
          brand,
          selectedModel.value,
          selectedSubmodel.value
        );
      } catch {
        imageData = null;
      }
      return imageData;
    });

    const showCalculatorSection = computed(() => {
      return (activeSection.value === 'calculator') | !isMobile.value;
    });

    const showQuoteSection = computed(() => {
      return (activeSection.value === 'quote') | !isMobile.value;
    });

    const calculationParams = computed(() => {
      return {
        model: selectedModel.value,
        submodel: selectedSubmodel.value,
        deposit: deposit.value,
        depositLimit: depositLimit.value,
        term: selectedTerm.value,
        frequency: selectedFreq.value,
        mileage: selectedMileage.value,
        product: financeProducts().filter(el => el.id === selectedProduct.value)[0]
          ? financeProducts().filter(el => el.id === selectedProduct.value)[0].id
          : financeProducts()[0].id,
        repayments: repayments.value,
        rrp: selectedSubmodelObject.value.RRP
      };
    });

    const caclculateGFV = async () => {
      if (cancelGFV.value) {
        cancelGFV.value.cancel();
        return false;
      }

      const clientId = process.env.VUE_APP_LANDROVER_CLIENT_ID;
      const urlToGetGFV = process.env.VUE_APP_GFV_URL;
      const apiRequestHeaders = {
        'content-type': 'application/json',
        'x-ibm-client-id': clientId,
        client_id: clientId
      };

      const termToMonths = selectedTerm.value.months ? selectedTerm.value.months : selectedTerm.value;
      const gfvPayload = {
        request_id: new Date().getTime(),
        vehicleId: selectedSubmodelObject.value.Code,
        term: termToMonths,
        mileage: ((selectedMileage.value * selectedTerm.value) / 12).toString()
      };
      cancelGFV.value = axios.CancelToken.source();
      return axios.post(urlToGetGFV, gfvPayload, {
        headers: apiRequestHeaders,
        cancelToken: cancelGFV.value.token
      });
    };

    const caclculateInterest = async (calculatorPayload) => {
      if (cancelInterest.value) {
        cancelInterest.value.cancel();
      }
      const clientId = process.env.VUE_APP_LANDROVER_CLIENT_ID;
      const urlForCalculator = process.env.VUE_APP_CALCULATOR_URL;
      const apiRequestHeaders = {
        'content-type': 'application/json',
        'x-ibm-client-id': clientId,
        // eslint-disable-next-line
                client_id: clientId,
      };
      cancelInterest.value = axios.CancelToken.source();
      return axios.post(urlForCalculator, calculatorPayload, {
        headers: apiRequestHeaders,
        cancelToken: cancelInterest.value.token
      });
    };

    const executeCalculation = async () => {
      if (!hasSubmodel.value) {
        calculatorRequestProcessing.value = false;
        return true;
      }
      const loanAmount =
                parseFloat(selectedSubmodelObject.value.RRP) +
                establishmentFees() -
                (deposit.value ? deposit.value : 0);

      const selectedTermMultiplier =
                selectedFreq.value === 'B' ? 26 : selectedFreq.value === 'M' ? 12 : 52;

      const theTerm = selectedTerm.value.months ? selectedTerm.value.months : selectedTerm.value;
      const calculatorPayload = {
        request_id: new Date().getTime(),
        loan_amt: loanAmount,
        amount_finaced: loanAmount,
        int_rate: interestRate(),
        term: (theTerm / 12) * selectedTermMultiplier,
        payment_method: 'R',
        payment_freq: selectedFreq.value,
        first_payment_dt: commonMixin.getFirstPaymentDate(selectedFreq),
        contract_dt: commonMixin.getToday(),
        financed_fees: 0,
        calculatorAmendement: []
      };
      calculatorRequestProcessing.value = true;
      if (selectedProduct.value === 'gfv') {
        try {
          const gfvResponse = await caclculateGFV();
          cancelGFV.value = null;
          const gfv = gfvResponse.data.Result[0].gfvValue;
          depositLimit.value = gfv
            ? parseFloat(selectedSubmodelObject.value.RRP) - gfv
            : parseFloat(selectedSubmodelObject.value.RRP);

          if (deposit.value && deposit.value > depositLimit.value) {
            deposit.value = Math.floor(depositLimit.value);
            showDepositTip.value = true;
            return false;
          } else if (!deposit.value || deposit.value < Math.floor(depositLimit.value)) {
            showDepositTip.value = false;
          }
          calculatorPayload.calculatorAmendement = [
            {
              WbsSeqNo: getWbsSeqNo.value,
              WbsAmount: gfv,
              WbsInclusive: 'Y'
            }
          ];
          // Get Interest
          const interestResponse = await caclculateInterest(calculatorPayload);

          cancelInterest.value = null;

          const totalInit = 0;
          const interestInit = 0;

          repayments.value =
                        interestResponse.data.paymentDetails.PaymentDetails[0].Amount;
          const totalCount =
                        interestResponse.data.paymentDetails.PaymentDetails.reduce(
                          function (accumulator, currentValue) {
                            return accumulator + currentValue.Amount;
                          },
                          totalInit
                        );
          const interest =
                        interestResponse.data.paymentDetails.PaymentDetails.reduce(
                          function (a, c) {
                            return a + c.Interest;
                          },
                          interestInit
                        );

          total.value = {
            interest: interest,
            gfv: gfv,
            loan: totalCount
          };
          calculatorRequestProcessing.value = false;
        } catch (error) {
          cancelGFV.value = null;
          calculatorRequestProcessing.value = false;
        }
      } else {
        try {
          // Get Interest
          const interestResponse = await caclculateInterest(calculatorPayload);

          cancelInterest.value = null;

          const totalInit = 0;
          const interestInit = 0;

          repayments.value =
                        interestResponse.data.paymentDetails.PaymentDetails[0].Amount;

          depositLimit.value = parseFloat(selectedSubmodelObject.value.RRP);

          const totalCount =
                        interestResponse.data.paymentDetails.PaymentDetails.reduce(
                          function (accumulator, currentValue) {
                            return accumulator + currentValue.Amount;
                          },
                          totalInit
                        );

          const interest =
                        interestResponse.data.paymentDetails.PaymentDetails.reduce(
                          function (a, c) {
                            return a + c.Interest;
                          },
                          interestInit
                        );

          total.value = {
            interest: interest,
            gfv: 0,
            deposit: deposit.value,
            loan: totalCount
          };

          calculatorRequestProcessing.value = false;
        } catch (error) {
          cancelGFV.value = null;
          calculatorRequestProcessing.value = false;
        }
      }
      initialLoad.value = false;
      return true;
    };

    const setActiveSection = (attr1) => {
      activeSection.value = attr1;
      if (isMobile.value) {
        window.scrollTo({ top: 200, behavior: 'smooth' });
      }
    };

    const submodelChange = async (e) => {
      if (e) {
        selectedSubmodel.value = e;
        const newSelectedSubmodelObject =
                    selectedModelSubmodels.value.find((el) => el.SubModel === e);
        selectedSubmodelObject.value = toRaw(newSelectedSubmodelObject);
        await nextTick();
        executeCalculation();
      } else {
        submodelChange(selectedModelSubmodels.value[0].SubModel);
      }
    };

    watch(selectedModel, async (newModel, oldModel) => {
      if (!newModel) {
        selectedModel.value = oldModel;
      } else {
        selectedModelSubmodels.value = selectSubmodels(newModel);
        await nextTick();
        selectedSubmodel.value = selectedModelSubmodels.value[0].SubModel;
      }
    });

    watch(selectedTerm, (newData) => {
      if (newData && !initialLoad.value) {
        executeCalculation();
      }
    });

    watch(selectedFreq, (newData) => {
      if (newData && !initialLoad.value) {
        executeCalculation();
      }
    });

    watch(selectedMileage, (newData) => {
      if (newData && !initialLoad.value) {
        executeCalculation();
      }
    });

    watch(selectedProduct, (newData) => {
      if (newData && !initialLoad.value) {
        executeCalculation();
      }
    });

    watch(deposit, (newData) => {
      if (newData > depositLimit.value) {
        deposit.value = Math.floor(depositLimit.value);
        showDepositTip.value = true;
      } else if (newData < Math.floor(depositLimit.value)) {
        showDepositTip.value = false;
        executeCalculation();
      }
    });

    const loadModels = () => {
      models.value = toRaw(modelsData).length ? toRaw(modelsData).map((el) => el.Model) : [];
      selectedModel.value = models.value[0];
      selectedModelSubmodels.value =
                selectSubmodels(selectedModel.value);
      modelsDataString.value = JSON.stringify(toRaw(modelsData));
    };

    const onResize = () => {
      isMobile.value = window.innerWidth < CONSTANTS.MOBILE_BREAKPOINT;
    };

    const init = () => {
      onResize();
      if (document.referrer && document.referrer.indexOf(location.hostname) === -1) {
        referrer.value = document.referrer;
      }

      window.addEventListener('resize', onResize, { passive: true });
      selectedProduct.value = financeProducts()[0].id;
      loadModels();
      selectedFreq.value = frequencyOptions()[0].id;
      selectedTerm.value = termOptions()[1].months;
      selectedMileage.value = mileageOptions()[0].kms;
    };

    const getCurrency = (value, noDecimals) => {
      if (!value) {
        return '...calculating';
      }
      if (noDecimals) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }).format(value);
      }
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    };

    function formatCarSubmodel(value) {
      const selectedCar = toRaw(modelsData).find(function (el) {
        return el.Model === selectedModel.value && el.SubModel === toRaw(value).label;
      });
      return selectedCar && selectedCar.SubModel
        ? { label: selectedCar.SubModel, rrp: selectedCar.RRP }
        : {};
    }

    onMounted(() => {
      init();
      calcDebounce.value = debounce(executeCalculation, 500);
      commonMixin.runOnPageLoad();
    });

    return {
      brand,
      modelsData,
      models,
      modelsDataString,
      selectedModel,
      selectedModelSubmodels,
      selectedSubmodel,
      selectedSubmodelObject,
      selectedProduct,
      deposit,
      depositLimit,
      selectedFreq,
      selectedTerm,
      selectedMileage,
      init,
      loadModels,
      isMobile,
      calculatorRequestProcessing,
      cancelInterest,
      frequencyWord,
      setActiveSection,
      activeSection,
      getCurrency,
      selectedModelImage,
      selectedModelImageSrcset,
      showCalculatorSection,
      showQuoteSection,
      financeProducts,
      termOptions,
      frequencyOptions,
      interestRate,
      mileageOptions,
      getRadioLabelText,
      modelsArray,
      submodelsArray,
      hasSubmodel,
      total,
      establishmentFees,
      formatCarSubmodel,
      showDepositTip,
      repayments,
      currencyOptions,
      whatIsIownLink,
      calculationParams,
      submodelChange,
      referrer,
      iirForDisclaimer,
      estFeeForDisclaimer,
      ppsrFeeForDisclaimer,
      brandWord,
      cleanSubmodelsArray,
      ratesText
    };
  }
});
</script>
