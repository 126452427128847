const CONSTANTS = {
  MOBILE_BREAKPOINT: 768,
  BRANDS: {
    LANDROVER: 'Land Rover'
  },
  FINANCE_PRODUCTS: [
    { id: 'gfv', title: 'LAND ROVER FREEDOM' },
    { id: 'cl', title: 'CONSUMER<br/> LOAN' },
    { id: 'bl', title: 'BUSINESS<br/> LOAN' }
  ],
  FREQUENCY_OPTIONS: [
    { id: 'M', title: 'MONTHLY' },
    { id: 'B', title: 'FORTNIGHTLY' },
    { id: 'W', title: 'WEEKLY' }
  ],
  MILEAGE_OPTIONS: [
    { id: '15000', label: '0 - 15,000', kms: 15000, value: 15000 },
    { id: '30000', label: '15,001 - 30,000', kms: 30000, value: 30000 }
  ],
  TERM_OPTIONS: [
    { id: '4', label: '48 months', months: 48, value: 48 },
    { id: '3', label: '36 months', months: 36, value: 36 },
    { id: '2', label: '24 months', months: 24, value: 24 },
    { id: '1', label: '12 months', months: 12, value: 12 }
  ],
  INITIAL_INTEREST_RATE: 10.50,
  ESTABLISHMENT_FEES: 293.39,
  EST_FEE: 286,
  PPSR_FEE: 7.39,
  CURRENCY_FIELD_OPTIONS: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    prefix: '$ ',
    numeralPositiveOnly: true,
    rawValueTrimPrefix: true
  },
  WHAT_IS_IOWN_LINK: {
    LANDROVER: 'https://www.landrover.co.nz/offers-and-finance/financial-services/land-rover-freedom'
  },
  HOMEPAGE_LINK: {
    LANDROVER: 'https://www.landrover.co.nz/'
  },
  RATES_TEXT: 'Our interest rates vary between 8.50% and 13.50% depending on your personal circumstances.',
  MODELS_GROUPED: [
    // { model: 'DEFENDER', group: ['90', '110', '130'] }
  ]
};

export default CONSTANTS;
